/* You can add global styles to this file, and also import other style files */
/* @import "@angular/material/prebuilt-themes/indigo-pink.css"; */


/* #region 300 */
@media only screen and (min-width: 300px) and (max-width: 767px)

/* and (orientation: portrait)  */
  {}

/* #endregion 300 */

/* #region 768 */
@media screen and (min-width: 768px) and (max-width: 959px) {}

/* #endregion  768 */

/* #region  960 */
@media screen and (min-width: 960px) and (max-width: 1279px) {}

/* #endregion 960 */

/* #region  1280 */
@media screen and (min-width: 1280px) and (max-width: 1919px) {}

/* #endregion  1280 */

/* #region 1920 */
@media screen and (min-width: 1920px) and (max-width: 5000px) {}

/* #endregion  1920 */

/* #region  */
/* #endregion */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  --cscDarkBlue: #242D3C;
  --cscLightBlue: #D4E2F4;
  --cscGold: #ffc529;
  --cscMidnightBlue: rgba(16, 32, 104); /*#201068 */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul li,
ol li {
  /* display: block; */
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}
button {
  font-family: 'Montserrat', sans-serif;
}
h1 {
  font-size: clamp(24px, 1.75rem, 2rem);
}
h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.17em;
}

h4 {
  font-size: 1.00em;
}

h5 {
  font-size: 0.83em;
}

h6 {
  font-size: 0.67em;
}
p {
  font-size: 1rem;
  line-height: 1.3;
}
i {
  font-size: 14px;
  vertical-align: super;
}

a {
  text-decoration: none;
}

.mat-drawer-container {
  background-color: white;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.active-list-item {
  background-color: blue;
  color: var(--cscGold) !important;
}

.routerLinkActive {
  background-color:  var(--cscMidnightBlue) !important;
  color: white !important;
}
.linkActiveLightBlue {
  background-color:  var(--cscLightBlue) !important;
  color: var(--cscMidnightBlue) !important;
}

.buttonRow button,
.buttonRow a {
  margin-right: 8px;
}

.buttonRow button {
  margin: 1rem .3rem;
}

.buttonRow {
  display: flex;
}

.greenBtn {
  padding: .5rem;
  background-color: green;
  color: white;
  cursor: pointer;
}

.background-red {
  background-color: rgb(153, 50, 50);
}

.snack-position {
  display: grid;
  place-items: center;
  margin-bottom: 200px;
}

.cscButtons {
  background: white;
  color: var(--cscMidnightBlue) !important;
  border: 1px solid var(--cscMidnightBlue) !important;
  margin: 10px 10px 10px 0;
  cursor: pointer;
}

.cscButtons:hover,
.cscButtons:active,
.cscButtons:focus {
  background: var(--cscMidnightBlue) !important;
  border: 1px solid var(--cscGold) !important;
  color: var(--cscGold) !important;
}

.lightBlueBtn {
  background: var(--cscLightBlue)!important;
  color:var(--cscBlue)!important;
  border: 1px solid var(--cscBlue)!important;
  cursor: pointer;
}
.lightBlueBtn:hover, .lightBlueBtn:focus, .lightBlueBtn:active  {
  background: white!important;
  color:var(--cscBlue)!important;
  border: 1px solid var(--cscBlue)!important;
}

.cscGold {
  color: #ffc529;
}

.inputFile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputFile+label {
  padding: .5rem;
  font-size: .9rem;
  font-weight: 500;
  color: white;
  background-color: var(--cscMidnightBlue) !important;
  display: inline-block;
  cursor: pointer;
  margin: .5rem 0;
}

.inputFile:focus+label,
.inputFile+label:hover {
  padding: .5rem;
  background-color: red;
}

.inputFile:focus+label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.cscDarkBlue {
  color: #242D3C;
}

.csc-btn {
  padding: .5rem;
  background-color: var(--cscMidnightBlue);
  color: var(--cscGold);
  cursor: pointer;
}

.redTxt {
  color: red;
}

.cscGoldText {
  color: #ffc529;
  }

.bold {
  font-weight: bold;
}

/* #region TABLE */
.customRowStyleAlternate {
  background-color: var(--cscLightBlue);
}

tr>td {
  padding: 0 1rem;
}

.tableSearch {
  grid-area: tableSearch;
}

.tablePaginator {
  grid-area: tablePaginator;
}

/* #endregion TABLE */

/* #region KEYFRAMES*/
@keyframes slideInFromLeftWithOpacity {
  from {
transform: translateX(-50px);
opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideInFromRightWithOpacity {
  from {
transform: translateX(50px);
opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
/* #endregion KEYFRAMES*/


/* #region BUTTONS */
.buttonRow button {
  margin: 10px 0;
  margin-right: 10px;
}

.export-buttons {
  margin: .5rem 0;
}

.greenBtn {
  padding: .2rem;
  background-color: green;
  color: white;
}

.assign-btn {
  padding: 0 .5rem !important;
  background-color: green;
  color: white;
  font-size: .7rem !important;
}

.redBtn {
  padding: .2rem;
  background-color: red;
  color: white;
}

.csc-btn {
  padding: .5rem;
  background-color: var(--cscMidnightBlue);
  color: var(--cscGold);
}

.csc-info-btn {
  padding: .5rem;
  background-color: var(--cscGold);
  color: var(--cscMidnightBlue);
}

.cscButtons {
  background: white;
  color: var(--cscMidnightBlue) !important;
  border: 1px solid var(--cscMidnightBlue) !important;
}

.cscButtons:hover,
.cscButtons:active,
.cscButtons:focus {
  background: var(--cscMidnightBlue) !important;
  border: 1px solid var(--cscGold) !important;
  color: var(--cscGold) !important;
}

.cscDarkBlueBtn {
  background:var(--cscDarkBlue) !important;
  color: var(--cscGold) !important;
  border: 1px solid var(--cscDarkBlue) !important;
}

.cscDarkBlueBtn:hover,
.cscDarkBlueBtn:active,
.cscDarkBlueBtn:focus {
  background:var(--cscGold) !important;
  border: 1px solid var(--cscDarkBlue) !important;
  color: var(--cscDarkBlue) !important;
}
.cscMidnightBlueBtn {
  background:var(--cscMidnightBlue) !important;
  color: var(--cscGold) !important;
  border: 1px solid var(--cscMidnightBlue) !important;
}

.cscMidnightBlueBtn:hover,
.cscMidnightBlueBtn:active,
.cscMidnightBlueBtn:focus {
  background:var(--cscGold) !important;
  border: 1px solid var(--cscMidnightBlue) !important;
  color: var(--cscMidnightBlue) !important;
}

.cscGold-buttons {
  background: var(--cscGold);
  color: var(--cscDarkBlue) !important;
  /* border: 1px solid var(--cscMidnightBlue)!important; */
}

.cscGold-buttons:hover,
.cscGold-buttons:active,
.cscGold-buttons:focus {
  background: var(--cscMidnightBlue) !important;
  border: 1px solid var(--cscMidnightBlue) !important;
  color: var(--cscGold) !important;
}
.cscGoldBtn {
  background: var(--cscGold);
  color: var(--cscMidnightBlue) !important;
  /* border: 1px solid var(--cscMidnightBlue)!important; */
}

.cscGoldBtn:hover,
.cscGoldBtn:active,
.cscGoldBtn:focus {
  background: white;
  color: var(--cscMidnightBlue) !important;
}
.cscGoldBtnInverse {
  background: var(--cscGold);
  color: var(--cscMidnightBlue) !important;
  /* border: 1px solid var(--cscMidnightBlue)!important; */
}

.cscGoldBtnInverse:hover,
.cscGoldBtnInverse:active,
.cscGoldBtnInverse:focus {
  color: var(--cscGold)!important;;
  background-color: var(--cscMidnightBlue)!important;
}

.cursorPointer {
  cursor: pointer;
}

/*  #endregion END BUTTONS */

/* #region TEXT COLORS */
.cscGold {
  color: #ffc529;
}

.cscDarkBlueText {
  color: var(--cscDarkBlue);
}
.cscMidnightBlueText {
  color: var(--cscMidnightBlue);
}
.cscLightBlueText {
  color: var(--cscLightBlue);
}

.redText {
  color: red;
}
.whiteText {
  color: white;
}

/*  #endregion END TEXT COLORS */

/* #region BACKGROUNDS */
.centerContent {
  display: flex;
  place-items: center;
}
.bgRed {
  background-color: red;
  padding: .2rem;
  color: white;
}

.bgGreen {
  padding: .2rem;
  background-color: green;
  color: white;
}

.bg-cscDarkBlue {
  background-color: var(--cscDarkBlue);
}

.bg-cscGold {
  background-color: var(--cscGold);
}


.employeeActive {
  background-color: green;
  color: white;
  padding: .5rem;
  width: fit-content;
}

.employeeInctive {
  background-color: red;
  color: white;
  padding: .5rem;
  width: fit-content;
}

/*  #endregion END BACKGROUNDS */

/* #region MESSAGES */
.errorMessage {
  background: red;
  color: white;
  padding: .5rem;
  width: max-content;
}

.warning {
  background-color: orange;
  color: white;
  padding: .5rem;
  width: max-content;
}

.snackError {
  background: red;
  color: white;
  margin-top: 85px !important;
}

.snackSuccess {
  margin-top: 85px !important;
  background: green;
  color: white;
  width: 100px !important;
}

:host .actionButtonLabel {
  color: white;
}

.loadingMsg {
  font-size: 1.2rem;
  text-align: center;
}

/* #endregion MESSAGES */

/* #region FONTS */
.bold {
  font-weight: bold;
}

/* END FONTS */

/* #region IMAGES */
img.resp-img {
  max-width: 100%;
  height: auto;
}

.blog-img-container img {
  max-width: 100%;
}

/* END IMAGES */


/* #region NG Material */
mat-icon {
  margin: 0 .5px 0 0;
}

.preLineWhiteSpace {
  white-space: pre-line;
}

/* #endregion Material */
@media only screen and (min-width: 300px) and (max-width: 767px)

/* and (orientation: portrait)  */
  {

  /* #region video */
  .youtube-holder {
    width: clamp(300px, 90%, 90vw);
    margin: 0 auto;
    padding: 1rem 0;
    padding-bottom: 1rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .ld-vid-intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  /* #endregion video */

}

@media screen and (min-width: 768px) and (max-width: 959px) {

  /* #region video */
  .youtube-holder {
    width: clamp(700px, 90%, 90vw);
    margin: 0 auto;
    padding: 1rem 0;
    padding-bottom: 1rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .ld-vid-intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  /* #endregion video */

 }

/* #region  960 */
@media screen and (min-width: 960px) and (max-width: 1279px) {

  /* #region video */
  .youtube-holder {
    width: clamp(900px, 90%, 90%);
    margin: 0 auto;
    padding: 1rem 0;
    padding-bottom: 1rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .ld-vid-intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  /* #endregion video */

}

/* #endregion 960 */

@media screen and (min-width: 1280px) and (max-width: 1919px) {

  /* #region video */
  .youtube-holder {
    width: clamp(1200px, 90%, 1600px);
    margin: 0 auto;
    padding: 1rem 0;
    padding-bottom: 1rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .ld-vid-intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  /* #endregion video */

}

@media screen and (min-width: 1920px) and (max-width: 5000px) {

  /* #region video */
  .youtube-holder {
    width: clamp(1400px, 70%, 1600px);
    margin: 0 auto;
    padding-bottom: 1rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .ld-vid-intro {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  /* #endregion video */

}
